import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController,NavParams } from '@ionic/angular';

@Component({
  selector: 'app-pop-up-filial',
  templateUrl: './pop-up-filial.page.html',
  styleUrls: ['./pop-up-filial.page.scss'],
})
export class PopUpFilialPage implements OnInit {

  public buttonValue: boolean = false;
  constructor(
    public router: Router,
    public modal: ModalController,
    private navParams: NavParams
  ) { }

  ngOnInit() {
  }

  goHome(){
    this.buttonValue = true;
    this.modal.dismiss(this.buttonValue);
  }

  closeModal(){
		this.modal.dismiss();
	}
}
