import { SharedModule } from '../../../shared/shared.module';
import { NgModule } from '@angular/core';
import { PopUpFilialPage } from './pop-up-filial.page';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [PopUpFilialPage],
  entryComponents: [PopUpFilialPage]
})
export class PopUpFilialPageModule {}
