import { Component, OnInit, Input } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { AppStorageProvider } from 'src/app/service/app-storage-provider';
import { PersonPipe } from 'src/app/pipe/person.pipe'
import { OverlayService } from 'src/app/service/overlay.service';
import { ServicesProvider } from 'src/app/service/services';
import { Validators, FormBuilder, FormGroup, FormControl} from '@angular/forms';
import { STORAGE_TYPE } from 'src/app/shared/model/local-storage-types.enum';

@Component({
  selector: 'app-redirect-pta',
  templateUrl: './redirect-pta.component.html',
  styleUrls: ['./redirect-pta.component.scss'],
})
export class RedirectPtaComponent implements OnInit {

  @Input() cpf_cnpj: string = ""
  @Input() numero_pta: string = ""
  @Input() numero_processo: string = ""
  @Input() outro: string = ""
  public data_user: any = [];
  public myForm: FormGroup;


  constructor(

    private router: Router, 
    private storage: AppStorageProvider,
    private service: ServicesProvider,
    public overlayService: OverlayService,
    public formBuilder: FormBuilder,

  ) {

    this.myForm = this.formBuilder.group({
      cpf_cnpj: ["", [Validators.required]],
      numero_pta: ["", [Validators.required]],
      numero_processo: ["", [Validators.required]],
      outro: ["", [Validators.required]]
    });

  }
   ngOnInit() {
    this.data_user = this.storage.get(STORAGE_TYPE.STORAGE_AUTUADOS)
  }

  openLink(data, pta?: any, numeroProcesso?: any, outro?: any) {
    let url = '/#/home';
    if (pta != undefined || pta != null) {
      window.open(url + '?' + "cpf_cnpj=" + data + '&' + "numero_pta=" + pta, '_blank');
    }
    if (numeroProcesso != undefined || numeroProcesso != null) {
      window.open(url + '?' + "cpf_cnpj=" + data + '&' + "numero_processo=" + numeroProcesso, '_blank');
    }
    if (outro != undefined || outro != null) {
      window.open(url + '?' + "cpf_cnpj=" + data + '&' + "outro=" + outro, '_blank');
    }
  }

}
